window.addEventListener("load", () => {
    // image lightbox
    const imageWrappers: Array<HTMLDivElement> = Array.from(
        document.querySelectorAll(".picture-wrapper")
    );

    for (const imageWrapper of imageWrappers) {
        imageWrapper.addEventListener("click", (evt) => {
            const image = imageWrapper.querySelector("img")!;
            console.log(image);
            if (imageWrapper.classList.contains("large")) {
                image.setAttribute(
                    "src",
                    image.getAttribute("data-reduced-src")!
                );
                imageWrapper.classList.remove("large");
            } else {
                image.setAttribute("src", image.getAttribute("data-full-src")!);
                imageWrapper.classList.add("large");
            }
        });
    }

    //// image slider
    // variables
    var collections = Array.from(
        document.querySelectorAll(".collection-wrapper")
    );

    const currentSliderPositions = new Array(collections.length);
    const currentSliderIndices = new Array(collections.length);
    const pictures: Array<any> = [];
    const leftArrows: Array<HTMLDivElement> = [];
    const rightArrows: Array<HTMLDivElement> = [];

    for (const collectionIndex in collections) {
        const collection = collections[collectionIndex];
        // const collectionName = collection.getAttribute('data-collection');

        currentSliderPositions[collectionIndex] = 0.0;
        currentSliderIndices[collectionIndex] = 1;

        var picturesInner = collection.querySelector(
            ".pictures-inner"
        ) as HTMLDivElement;
        leftArrows.push(collection.querySelector(".left-arrow")!);
        rightArrows.push(collection.querySelector(".right-arrow")!);
        //   var leftArrow = collection.querySelector(
        //       ".left-arrow"
        //   ) as HTMLDivElement;
        //   var rightArrow = collection.querySelector(
        //       ".right-arrow"
        //   ) as HTMLDivElement;
        // initialize for active JS
        collection.classList.add("slidable");
        leftArrows[collectionIndex].classList.add("hidden");

        let maxPictureHeight = 0;
        pictures.push(
            Array.from(picturesInner.querySelectorAll(".picture-wrapper"))
        );
        if (pictures[collectionIndex].length < 2) {
            rightArrows[collectionIndex].classList.add("hidden");
        }
        for (const pictureIndex in pictures[collectionIndex]) {
            let pictureIndexInt = parseInt(pictureIndex);
            maxPictureHeight = Math.max(
                maxPictureHeight,
                pictures[collectionIndex][pictureIndexInt].clientHeight
            );
            if (pictureIndexInt + 1 != currentSliderIndices[collectionIndex]) {
                pictures[collectionIndex][pictureIndexInt].classList.add(
                    "hidden"
                );
            } else {
                pictures[collectionIndex][pictureIndexInt].classList.remove(
                    "hidden"
                );
            }
        }
        picturesInner.style.height = maxPictureHeight + "px";

        // listen to clicks on arrows
        rightArrows[collectionIndex].addEventListener("click", function () {
            currentSliderIndices[collectionIndex] += 1;
            // currentSliderPositions[collectionIndex] =
            //     (1 - currentSliderIndices[collectionIndex]) *
            //     picturesInner.querySelector(".picture-wrapper")!.scrollWidth;
            // picturesInner.style.transform =
            //     "translateX(" + currentSliderPositions[collectionIndex] + "px)";
            if (
                currentSliderIndices[collectionIndex] ===
                pictures[collectionIndex].length
            ) {
                this.classList.add("hidden");
            }
            leftArrows[collectionIndex].classList.remove("hidden");
            for (const pictureIndex in pictures[collectionIndex]) {
                let pictureIndexInt = parseInt(pictureIndex);
                if (
                    pictureIndexInt + 1 !=
                    currentSliderIndices[collectionIndex]
                ) {
                    pictures[collectionIndex][pictureIndexInt].classList.add(
                        "hidden"
                    );
                } else {
                    pictures[collectionIndex][pictureIndexInt].classList.remove(
                        "hidden"
                    );
                }
            }
        });
        leftArrows[collectionIndex].addEventListener("click", function () {
            currentSliderIndices[collectionIndex] -= 1;
            // currentSliderPositions[collectionIndex] =
            //     (1 - currentSliderIndices[collectionIndex]) *
            //     picturesInner.querySelector(".picture-wrapper")!.scrollWidth;
            // picturesInner.style.transform =
            //     "translateX(" + currentSliderPositions[collectionIndex] + "px)";
            if (currentSliderIndices[collectionIndex] === 1) {
                this.classList.add("hidden");
            }
            rightArrows[collectionIndex].classList.remove("hidden");
            for (const pictureIndex in pictures[collectionIndex]) {
                let pictureIndexInt = parseInt(pictureIndex);
                if (
                    pictureIndexInt + 1 !=
                    currentSliderIndices[collectionIndex]
                ) {
                    pictures[collectionIndex][pictureIndexInt].classList.add(
                        "hidden"
                    );
                } else {
                    pictures[collectionIndex][pictureIndexInt].classList.remove(
                        "hidden"
                    );
                }
            }
        });
    }

    let resizeTimer;
    window.addEventListener("resize", function () {
        window.clearTimeout(resizeTimer);
        resizeTimer = window.setTimeout(function () {
            for (const collectionIndex in collections) {
                currentSliderPositions[collectionIndex] =
                    (1 - currentSliderIndices[collectionIndex]) *
                    picturesInner.querySelector(".project-wrapper")!
                        .scrollWidth;
                picturesInner.style.transform =
                    "translateX(" +
                    currentSliderPositions[collectionIndex] +
                    "px)";
            }
        }, 200);
    });
});
